// extracted by mini-css-extract-plugin
export var donutText = "heroStyle-module--donutText--aa4d7";
export var fadeInAnimation = "heroStyle-module--fadeInAnimation--9afa5";
export var heroContainer = "heroStyle-module--heroContainer--93d5a";
export var heroImage = "heroStyle-module--heroImage--092a6";
export var heroText = "heroStyle-module--heroText--0f148";
export var kolacheText = "heroStyle-module--kolacheText--5759d";
export var premiumText = "heroStyle-module--premiumText--8a35b";
export var productText = "heroStyle-module--productText--a69bd";
export var qualityText = "heroStyle-module--qualityText--7ec3e";
export var slideInLeft = "heroStyle-module--slideInLeft--4c80c";
export var slideInRight = "heroStyle-module--slideInRight--9b3d8";